import React from "react"
import { graphql } from "gatsby"
import { Section } from "elements-ui"
import { SEO } from "@maker-ui/seo"

import ServiceHeader from "../components/ServiceHeader"
import PortableText from "../components/PortableText"
import Certifications from "../components/Certifications"
import Raves from "../components/Raves"

export default ({ data }) => {
  const {
    _rawBody,
    name,
    subtitle,
    raves,
    seo,
    certifications,
  } = data.allSanityService.nodes[0]

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <ServiceHeader>{name}</ServiceHeader>
      <Section
        sx={{
          variant: "pageBody",
          img: {
            height: [270, "100%"],
            width: "100%",
            mb: 40,
            objectFit: "cover",
            boxShadow: "4px 4px 8px 1px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>{subtitle}</h2>
        <PortableText blocks={_rawBody} />
      </Section>
      <Raves raves={raves} service="Coaching" />
      <Certifications certs={certifications} />
    </>
  )
}

export const query = graphql`
  query {
    allSanityService(filter: { slug: { eq: "coaching" } }) {
      nodes {
        ...ServiceFragment
      }
    }
  }
`
